<mat-card>
  <mat-card-title class="mb-2">Vollmacht generieren</mat-card-title>

  <form #documentForm="ngForm">
    <div class="flex flex-column gap-4">
      <div class="flex gap-2">
        <mat-form-field class="w-6">
          <mat-label>Vorname</mat-label>
          <input matInput type="text" name="firstName" [(ngModel)]="data.firstName" disabled>
        </mat-form-field>
        <mat-form-field class="w-6">
          <mat-label>Nachname</mat-label>
          <input matInput type="text" name="lastName" [(ngModel)]="data.lastName" disabled>
        </mat-form-field>
      </div>
      <div class="flex gap-2">
        <mat-form-field class="w-6">
          <mat-label>Geburtsdatum</mat-label>
          <input matInput name="birthday" [matDatepicker]="picker" [(ngModel)]="data.birthday" disabled>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="w-6"></div>
      </div>
      <mat-form-field>
        <mat-label>Straße, Hausnummer</mat-label>
        <input matInput type="text" name="street" [(ngModel)]="data.street" disabled>
      </mat-form-field>
      <div class="flex gap-2">
        <mat-form-field class="w-2">
          <mat-label>Postleitzahl</mat-label>
          <input matInput type="text" name="postalCode" [(ngModel)]="data.postalCode" disabled>
        </mat-form-field>
        <mat-form-field class="w-10">
          <mat-label>Ort</mat-label>
          <input matInput type="text" name="city" [(ngModel)]="data.city" disabled>
        </mat-form-field>
      </div>

      <h4 class="m-0">Unterschrift</h4>

      <div class="flex gap-2">
        <mat-form-field class="w-6">
          <mat-label>Ort</mat-label>
          <input matInput type="text" name="signaturePlace" placeholder="Ort" [(ngModel)]="data.signaturePlace">
        </mat-form-field>
        <mat-form-field class="w-6">
          <mat-label>Datum</mat-label>
          <input matInput name="signatureDate" [matDatepicker]="signatureDatePicker" placeholder="Datum"
                 [(ngModel)]="data.signatureDate">
          <mat-datepicker-toggle matSuffix [for]="signatureDatePicker"></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <mat-datepicker #signatureDatePicker></mat-datepicker>
    </div>
  </form>

  <mat-card-actions align="end" class="p-0 gap-2">
    <app-download-button [disabled]="!documentForm.form.valid"
                         [data]="data"
                         [(loading)]="loading"></app-download-button>
    <app-signature-button [includeProxy]="true"
                          [includeBrokerContract]="false"
                          [disabled]="!documentForm.form.valid"
                          [data]="data"
                          [(loading)]="loading"></app-signature-button>
  </mat-card-actions>
</mat-card>
