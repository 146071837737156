import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import {EMPTY, Subject} from 'rxjs';

import {
  BrokerContractData,
  BrokerContractItem,
  BrokerContractStatus,
  Consent,
  DocumentGeneratorService
} from '../../services/document-generator.service';
import {GeoLocationService} from '../../services/geolocation.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {FormGroupDirective, NgForm, UntypedFormControl} from '@angular/forms';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {ConfirmationService, ToastService} from "@taures/angular-commons";

/** Error when invalid control is dirty, touched, or submitted. */
export class ShowOnInvalidErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid;
  }
}

@Component({
  selector: 'app-broker-contract',
  templateUrl: './broker-contract.component.html',
  styleUrls: ['./broker-contract.component.css'],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnInvalidErrorStateMatcher},
  ]
})
export class BrokerContractComponent implements OnInit, OnDestroy {
  consent = Consent;
  data: BrokerContractData;
  loading = false;
  generatedProxy = false;
  @ViewChild('downloadLink', {static: true})
  downloadLink: ElementRef;
  private destroy = new Subject<void>();
  private url: string;

  constructor(private route: ActivatedRoute,
              private documentGeneratorService: DocumentGeneratorService,
              private geoLocationService: GeoLocationService,
              private toastService: ToastService,
              private confirmationService: ConfirmationService,
              private titleService: Title,
              @Inject(DOCUMENT) private document: Document,
              private changeDetector: ChangeDetectorRef,
              private platform: Platform) {
    this.data = {fixedLine: {}, mobile: {}, email: {}} as any;
  }

  ngOnInit(): void {
    this.route.data.pipe(
      takeUntil(this.destroy),
    ).subscribe(({data}) => {
        this.titleService.setTitle(`Maklervertrag - ${data.lastName}, ${data.firstName} - TauRes`);
        this.data = data;
        this.changeDetector.markForCheck();
      }
    );
    this.geoLocationService.getLocality()
      .pipe(takeUntil(this.destroy))
      .subscribe((locality) => {
        this.data.signaturePlace = locality;
        this.data.signatureDate = new Date();
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    if (this.url) {
      URL.revokeObjectURL(this.url);
    }
  }

  downloadBrokerContract(): void {
    if (!this.hasLatestProxyContract() && !this.generatedProxy) {
      this.confirmationService.showPopup({
        message: 'Bitte zuerst Maklervollmacht vom Kunden einholen.',
        confirmationType: 'warning',
        closable: false,
        showCancelButton: false,
        confirmButtonText: 'Ok'
      });
      return;
    }
    this.loading = true;

    this.documentGeneratorService.downloadDocument('broker-contract', this.data)
      .subscribe(file => {
        this.download(file);
        this.loading = false;
        this.changeDetector.markForCheck();
      });
  }

  downloadMandate(): void {
    this.loading = true;
    this.documentGeneratorService.downloadDocument('proxy', this.data)
      .pipe(takeUntil(this.destroy))
      .subscribe(file => {
        this.download(file);
        this.generatedProxy = true;
        this.loading = false;
        this.changeDetector.markForCheck();
      });
  }

  download(file: File): void {
    if (file) {
      if (this.url) {
        URL.revokeObjectURL(this.url);
      }
      this.url = URL.createObjectURL(file);
      if (this.platform.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        const newWindow = this.document.defaultView.open(this.url, '_blank');
        if (!newWindow || newWindow.closed) {
          this.toastService.queueToastMessage({
            notificationType: 'warning',
            message: 'Um das Dokument anzuzeigen, müssen Popups im Browser erlaubt sein.'
          })
        }
      } else {
        const anchor = this.downloadLink.nativeElement as HTMLAnchorElement;
        anchor.download = file.name;
        anchor.href = this.url;
        anchor.target = '_blank';
        anchor.click();
      }
    }
  }

  hasLatestProxyContract(): boolean {
    return this.data.latestProxyContractVersion;
  }

  reset(): void {
    this.confirmationService.showPopup({
      message: 'Die Daten werden auf den aktuellen CRM-Stand zurückgesetzt.',
      confirmationType: "warning"
    }).afterClosed().pipe(
      switchMap((confirmed) => confirmed ? this.documentGeneratorService.getBrokerContractData('' + this.data.customerId, true) : EMPTY)
    ).subscribe(result => {
      if (result) {
        this.data = result
        this.changeDetector.markForCheck();
      }
    });
  }

  resetUnrequiredDates(data: any): void {
    if (!data.selected) {
      if (data.id === 47) {
        this.data.serviceContractDate = null;
      }
      if (data.id === 49) {
        this.data.creditBrokerageAgreementDate = null;
      }
    }
  }

  getItem(id: number) : BrokerContractItem {
    const result = this.data?.items?.find(item => item.id === id);
    return result || new BrokerContractItem(id, 'unbekannt');
  }

  hasContract(): boolean {
    return this.data?.status !== BrokerContractStatus.None
  }

  hasCurrentContract(): boolean {
    return this.data?.status === BrokerContractStatus.Current
  }

  hasOutdatedContract(): boolean {
    return this.data?.status === BrokerContractStatus.Outdated
  }
}
