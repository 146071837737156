<mat-card>
  <mat-card-title class="mb-2">Maklervertrag generieren</mat-card-title>

  <tr-info-box *ngIf="hasContract()">
    <div *ngIf="hasCurrentContract()">
      <span>Es besteht aktuell ein Maklervertrag. Du kannst zu diesem Maklervertrag einen Nachtrag erstellen oder einen neuen Maklervertrag generieren.
        Bestehende Daten werden übernommen und können geändert werden.</span>
      <mat-radio-group color="primary" class="flex flex-column" [(ngModel)]="data.supplement">
        <mat-radio-button class="pt-2" [value]="true">Nachtrag zum bestehenden Maklervertrag erstellen</mat-radio-button>
        <mat-radio-button class="pt-2" [value]="false">Neuen Maklervertrag erstellen</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="hasOutdatedContract()">
      <p>Die Version des aktuell hinterlegten Maklervertrags ist nicht aktuell.
        Bei inhaltlichen Änderungen wird ein neuer Maklervertrag mit der neuesten Versionsnummer erstellt. Bestehende Daten werden übernommen und können geändert werden.</p>
    </div>
  </tr-info-box>

  <form #documentForm="ngForm">
    <div class="flex flex-column gap-2">
      <h4>Auftraggeber</h4>

      <div class="flex gap-2">
        <mat-form-field class="w-6">
          <mat-label>Vorname</mat-label>
          <input matInput type="text" name="firstName" [(ngModel)]="data.firstName" disabled>
        </mat-form-field>
        <mat-form-field class="w-6">
          <mat-label>Nachname</mat-label>
          <input matInput type="text" name="lastName" [(ngModel)]="data.lastName" disabled>
        </mat-form-field>
      </div>
      <div class="flex gap-2">
        <mat-form-field class="w-6">
          <mat-label>Geburtsdatum</mat-label>
          <input matInput name="birthday" [matDatepicker]="birthdayPicker" [(ngModel)]="data.birthday" disabled>
          <mat-datepicker #birthdayPicker></mat-datepicker>
        </mat-form-field>
        <div class="w-6"></div>
      </div>
      <mat-form-field>
        <mat-label>Straße, Hausnummer</mat-label>
        <input matInput type="text" name="street" [(ngModel)]="data.street" disabled>
      </mat-form-field>
      <div class="flex gap-2">
        <mat-form-field class="w-2">
          <mat-label>Postleitzahl</mat-label>
          <input matInput type="text" name="postalCode" [(ngModel)]="data.postalCode" disabled>
        </mat-form-field>
        <mat-form-field class="w-10">
          <mat-label>Ort</mat-label>
          <input matInput type="text" name="city" [(ngModel)]="data.city" disabled>
        </mat-form-field>
      </div>

      <tr-info-box *ngIf="data.created">
        <div>
          <p>Letzter gespeicherter Stand (Maklervertrag-Generator): {{data.created | date:'dd.MM.YYYY'}}</p>
          <p>Du kannst das Eingabeformular auch auf den aktuellen CRM-Stand zurücksetzen. Der letzte gespeicherte Stand des Maklervertrag-Generators wird verworfen.</p>
          <button class="mt-1" mat-stroked-button (click)="reset()">Zurücksetzen</button>
        </div>
      </tr-info-box>

      <h4>Optionale weitere Einverständniserklärung zur Datennutzung</h4>
      <span class="mb-4">Die Einverständniserklärung dient der weiterführenden Beratungsmöglichkeit über die bestehende Geschäftsbeziehung hinaus.
        TauRes nutzt die Daten intern und wird diese nicht an Dritte weitergeben.</span>

      <div class="flex contact">
        <label class="contact">Festnetz</label>
        <mat-radio-group name="fixedLine" color="primary" [(ngModel)]="data.fixedLine.consent" class="flex gap-4 w-full">
          <mat-radio-button [value]="consent.No">nein</mat-radio-button>
          <mat-radio-button [value]="consent.Yes">uneingeschränkt</mat-radio-button>
          <mat-radio-button [value]="consent.Restricted">eingeschränkt unter</mat-radio-button>
          <mat-form-field class="flex-grow-1">
            <mat-label>Rufnummer</mat-label>
            <input matInput type="text" name="fixedLineRestrictTo" placeholder="Rufnummer"
                   [(ngModel)]="data.fixedLine.restrictTo"
                   [required]="data.fixedLine.consent === consent.Restricted"
                   [disabled]="data.fixedLine.consent !== consent.Restricted">
          </mat-form-field>
        </mat-radio-group>
      </div>

      <div class="flex contact">
        <label class="contact">Mobil</label>
        <mat-radio-group name="mobile" color="primary" [(ngModel)]="data.mobile.consent" class="flex gap-4 w-full">
          <mat-radio-button [value]="consent.No">nein</mat-radio-button>
          <mat-radio-button [value]="consent.Yes">uneingeschränkt</mat-radio-button>
          <mat-radio-button [value]="consent.Restricted">eingeschränkt unter</mat-radio-button>
          <mat-form-field class="flex-grow-1">
            <mat-label>Rufnummer</mat-label>
            <input matInput type="text" name="mobileRestrictTo" placeholder="Rufnummer"
                   [(ngModel)]="data.mobile.restrictTo"
                   [required]="data.mobile.consent === consent.Restricted"
                   [disabled]="data.mobile.consent !== consent.Restricted">
          </mat-form-field>
        </mat-radio-group>
      </div>

      <div class="flex contact">
        <label class="contact">E-Mail</label>
        <mat-radio-group name="email" color="primary" [(ngModel)]="data.email.consent" class="flex gap-4 w-full">
          <mat-radio-button [value]="consent.No">nein</mat-radio-button>
          <mat-radio-button [value]="consent.Yes">uneingeschränkt</mat-radio-button>
          <mat-radio-button [value]="consent.Restricted">eingeschränkt unter</mat-radio-button>
          <mat-form-field class="flex-grow-1">
            <mat-label>E-Mail-Adresse</mat-label>
            <input matInput type="text" name="emailRestrictTo" placeholder="E-Mail-Adresse"
                   [(ngModel)]="data.email.restrictTo"
                   [required]="data.email.consent === consent.Restricted"
                   [disabled]="data.email.consent !== consent.Restricted">
          </mat-form-field>
        </mat-radio-group>
      </div>

      <h4>Vermittlung und Verwaltung neuer Verträge</h4>

      <h4 class="m-0">1. Vermögensaufbau</h4>

      <app-broker-contract-item [item]="getItem(49)" (selectionChange)="resetUnrequiredDates($event)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(47)" (selectionChange)="resetUnrequiredDates($event)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(48)"></app-broker-contract-item>

      <h4 class="m-0">2. Einkommens- und Pflegevorsorge</h4>

      <app-broker-contract-item [item]="getItem(24)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(53)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(25)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(23)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(40)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(54)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(26)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(28)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(27)"></app-broker-contract-item>

      <h4 class="m-0">3. Altersvorsorge</h4>

      <app-broker-contract-item [item]="getItem(20)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(21)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(22)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(55)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(56)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(57)"></app-broker-contract-item>

      <h4 class="m-0">4. Gesundheitsvorsorge</h4>

      <app-broker-contract-item [item]="getItem(29)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(30)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(46)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(31)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(32)"></app-broker-contract-item>

      <h4 class="m-0">5. Vermögensschutz</h4>

      <app-broker-contract-item [item]="getItem(33)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(34)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(36)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(58)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(35)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(37)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(38)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(39)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(42)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(43)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(59)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(60)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(41)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(44)"></app-broker-contract-item>

      <h4 class="m-0">6. Sonstiges</h4>

      <app-broker-contract-item [item]="getItem(50)"></app-broker-contract-item>
      <app-broker-contract-item [item]="getItem(51)"></app-broker-contract-item>
      <div class="flex gap-2">
        <mat-checkbox name="otherSelected" color="primary" class="other" [(ngModel)]="getItem(52).selected" [disabled]="getItem(52).existing"></mat-checkbox>
        <mat-form-field class="flex-grow-1">
          <mat-label>{{getItem(52).title}}</mat-label>
          <input matInput type="text" name="other" [placeholder]="getItem(52).title" [(ngModel)]="getItem(52).note"
                 [required]="getItem(52).selected" [disabled]="!getItem(52).selected">
        </mat-form-field>
        <mat-form-field class="w-20rem">
          <mat-label>gültig bis</mat-label>
          <input name="otherValidTo" matInput [matDatepicker]="pickerOther" placeholder="gültig bis" [(ngModel)]="getItem(52).validTo" [disabled]="!getItem(52).selected">
          <mat-datepicker-toggle matSuffix [for]="pickerOther"></mat-datepicker-toggle>
          <mat-datepicker #pickerOther></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex gap-2">
        <h4 class="flex-grow-1 input-header">Cash-Konto und Darlehensvermittlung</h4>
        <mat-form-field class="w-20rem">
          <mat-error>Bitte Datum der Vereinbarung angeben</mat-error>
          <mat-label>gemäß separater Vereinbarung vom</mat-label>
          <input matInput name="creditBrokerageAgreementDate" [matDatepicker]="picker2" placeholder="gemäß separater Vereinbarung vom"
                 [required]="getItem(49).selected || getItem(50).selected || getItem(51).selected"
                 [disabled]="!getItem(49).selected && !getItem(50).selected && !getItem(51).selected" [(ngModel)]="data.creditBrokerageAgreementDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex gap-2">
        <h4 class="flex-grow-1 input-header">Investmentfonds und Vermögenswirksame Leistungen</h4>
        <mat-form-field class="w-20rem">
          <mat-label>gemäß separatem Servicevertrag vom</mat-label>
          <mat-error>Bitte Datum des Servicevertrags angeben</mat-error>
          <input matInput name="serviceContractDate" [matDatepicker]="picker"
                 placeholder="gemäß separatem Servicevertrag vom" [required]="getItem(47).selected || getItem(48).selected"
                 [disabled]="!getItem(47).selected && !getItem(48).selected" [(ngModel)]="data.serviceContractDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <h4>Unterschrift</h4>

      <div class="flex gap-2">
        <mat-form-field class="w-6">
          <mat-label>Ort</mat-label>
          <input matInput type="text" name="signaturePlace" placeholder="Ort" [(ngModel)]="data.signaturePlace">
        </mat-form-field>
        <mat-form-field class="w-6">
          <mat-label>Datum</mat-label>
          <input matInput name="signatureDate" [matDatepicker]="signatureDatePicker" placeholder="Datum"
                 [(ngModel)]="data.signatureDate">
          <mat-datepicker-toggle matSuffix [for]="signatureDatePicker"></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <mat-datepicker #signatureDatePicker></mat-datepicker>
    </div>
  </form>

  <mat-card-actions align="end" class="p-0 gap-2">
    <a #downloadLink style="display: none" target="_blank"></a>
    <button *ngIf="!hasLatestProxyContract()" mat-raised-button color="primary" type="button" (click)="downloadMandate()" [disabled]="!documentForm.form.valid || loading">
      <div class="flex gap-2 align-items-center">
        <span>Vollmacht erstellen</span> <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
      </div>
    </button>
    <button mat-raised-button color="primary" type="button" (click)="downloadBrokerContract()" [disabled]="!documentForm.form.valid || loading">
      <div class="flex gap-2 align-items-center">
        <span>{{data.supplement ? 'Nachtrag erstellen' : 'Maklervertrag erstellen'}}</span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
      </div>
    </button>
    <app-signature-button [includeProxy]="!hasLatestProxyContract()"
                          [includeBrokerContract]="true"
                          [isSupplement]="data.supplement"
                          [data]="data"
                          [disabled]="!documentForm.form.valid"
                          [(loading)]="loading"></app-signature-button>
  </mat-card-actions>
</mat-card>
