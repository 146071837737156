import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
import {DocumentData, DocumentGeneratorService} from "../../services/document-generator.service";
import {KeycloakTokenService, ToastService} from "@taures/angular-commons";
import {catchError, tap} from "rxjs/operators";
import {combineLatest, EMPTY} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-signature-button',
  templateUrl: './signature-button.component.html',
  styleUrl: './signature-button.component.scss',
  animations: []
})
export class SignatureButtonComponent {

  @Input() data!: DocumentData;
  @Input() includeProxy!: boolean;
  @Input() includeBrokerContract!: boolean;
  @Input() isSupplement: boolean = false;

  @Input()
  disabled = false;

  @Input()
  loading = false;
  @Output()
  loadingChange = new EventEmitter<boolean>();

  protected isUserInSignatureBeta = this.keycloakTokenService.hasRole("user", "ci_unterschrift-backend");

  constructor(matIconRegistry: MatIconRegistry,
              domSanitizer: DomSanitizer,
              private documentGeneratorService: DocumentGeneratorService,
              private toastService: ToastService,
              private keycloakTokenService: KeycloakTokenService) {
    matIconRegistry.addSvgIcon('sign', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sign.svg'));
  }

  sendToSignature() {
    this.loadingChange.next(true)
    const sendProxyToSignature$ = !this.includeProxy ? EMPTY : this.documentGeneratorService.sendToSignature$("proxy", this.data)
      .pipe(
        tap(() => this.showSuccessToast("Vollmacht")),
        catchError((e) => {this.showErrorToast("Vollmacht", e); return EMPTY})
      );
    const sendBrokerContractToSignature$ = !this.includeBrokerContract ? EMPTY : this.documentGeneratorService.sendToSignature$("broker-contract", this.data)
      .pipe(
        tap(() => this.showSuccessToast(this.isSupplement? "Nachtrag": "Maklervertrag")),
        catchError((e) => {this.showErrorToast(this.isSupplement? "Nachtrag": "Maklervertrag", e); return EMPTY})
      );

    combineLatest([sendProxyToSignature$, sendBrokerContractToSignature$])
      .subscribe({
        complete: () => this.loadingChange.next(false)
      });
  }

  private showSuccessToast(documentTypePrefix: 'Nachtrag' | 'Vollmacht' | 'Maklervertrag') {
    this.toastService.queueToastMessage({
      message: `${documentTypePrefix} wurde dem Unterschriften-Manager hinzugefügt.`,
      notificationType: 'success'
    });
  }

  private showErrorToast(documentTypePrefix: 'Nachtrag' | 'Vollmacht' | 'Maklervertrag', e: HttpErrorResponse) {
    const message = `${documentTypePrefix} konnte nicht dem Unterschriften-Manager hinzugefügt werden. ${e.error?.error || ""}`;
    this.toastService.queueToastMessage({
      message: message,
      notificationType: 'error',
      preventDisplayDuplicate: false
    })
  }
}
